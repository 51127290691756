import { useState } from "react";

import Card from "../card";

import { useApi } from "../../hooks/useApi";

import * as Sentry from "@sentry/react";

function SupportSection() {
  const [message, setMessage] = useState("");
  const api = useApi();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await api.post("/v2/users/support", { message });
      setMessage("");
      // Optionally, show a success message to the user
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error sending support message:", error);
      // Optionally, show an error message to the user
    }
  };

  return (
    <Card>
      <h2 className="text-xl font-semibold mb-4">Support</h2>
      <form onSubmit={handleSubmit}>
        <textarea
          className="w-full p-2 border rounded bg-gray-100 border-gray-200 text-sm focus:border-purple-700 focus:ring-purple-500"
          rows={4}
          placeholder="Type your question or issue here..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        ></textarea>
        <div className="flex items-center justify-between mt-4">
          <p className="text-sm text-gray-500">
            You can also reach us at{" "}
            <a href="mailto:support@braidsocial.io" className="underline">
              support@braidsocial.io
            </a>
          </p>
          <button
            type="submit"
            className="mt-2 bg-white text-black px-4 py-2 rounded-[8px] transition-colors border border-black text-sm hover:border-purple-700 hover:ring-purple-500 hover:text-purple-700"
          >
            Send
          </button>
        </div>
      </form>
    </Card>
  );
}

export default SupportSection;
