import React, { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import * as Sentry from "@sentry/react";
import { useApi } from "../hooks/useApi";
import { useUserProfile } from "../hooks/useUserProfile";

import SubscriptionSection from "../components/settings/subscriptionSection";
import SupportSection from "../components/settings/supportSection";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

function StripeWrapper({ children }: { children: React.ReactNode }) {
  return <Elements stripe={stripePromise}>{children}</Elements>;
}

function StripeHandler() {
  const stripe = useStripe();
  const api = useApi();
  const { subscription, refetchUserData } = useUserProfile();

  useEffect(() => {
    if (!stripe) return;

    const setupIntentSecret = new URLSearchParams(window.location.search).get(
      "setup_intent_client_secret",
    );
    const setupIntentId = new URLSearchParams(window.location.search).get(
      "setup_intent",
    );

    if (setupIntentSecret && setupIntentId) {
      stripe
        .retrieveSetupIntent(setupIntentSecret)
        .then(async ({ setupIntent }) => {
          if (setupIntent && setupIntent.status === "succeeded") {
            try {
              const response = await api.post(
                "/v2/users/finalize-subscription",
                {
                  setup_intent_id: setupIntentId,
                },
              );
              if (response.data.status === "active") {
                await refetchUserData();
                // Clear the search params after processing
                window.history.replaceState(
                  {},
                  document.title,
                  window.location.pathname,
                );
              } else {
                throw new Error("Subscription not active after finalization");
              }
            } catch (error) {
              Sentry.captureException(error);
              console.error("Error finalizing subscription:", error);
            }
          } else {
            Sentry.captureMessage("Setup was not successful");
            console.error("Setup was not successful");
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error("Error retrieving setup intent:", error);
        });
    }
  }, [stripe, api, refetchUserData, subscription]);

  return null;
}

function Settings() {
  return (
    <StripeWrapper>
      <StripeHandler />
      <div className="container mx-auto">
        <div className="space-y-6">
          <SubscriptionSection />
          <SupportSection />
        </div>
      </div>
    </StripeWrapper>
  );
}

export default Settings;
