import { SlackUser } from "../types/user";

const generateSlackDeepLink = (slackUser?: SlackUser) => {
  if (!slackUser) return "";

  const { app_id, team_id } = slackUser;

  return `slack://app?team=${team_id}&id=${app_id}&tab=home`;
};

export { generateSlackDeepLink };
