import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faUser } from "@fortawesome/free-solid-svg-icons";

function Header() {
  return (
    <header className="bg-white shadow">
      <div className="w-[730px] mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
        <div className="flex items-center">
          <h1 className="text-xl font-bold text-brand font-dmSans">
            <a href="/">Braid Teams</a>
          </h1>
        </div>
        <div className="font-dmSans font-bold">
          <Link
            to="/profile"
            className="text-brand px-3 py-2 rounded-md text-md hover:bg-brand hover:bg-opacity-10"
          >
            <FontAwesomeIcon className="pr-2" icon={faUser} size="lg" /> Profile
          </Link>
          <Link
            to="/settings"
            className="text-brand px-3 py-2 rounded-md text-md hover:bg-brand hover:bg-opacity-10"
          >
            <FontAwesomeIcon className="pr-2" icon={faCog} size="lg" /> Settings
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Header;
