import axios from "axios";

const BASE_URL = import.meta.env.VITE_BASE_API_URL;

export const createApi = (getToken: () => Promise<string | null>) => {
  const api = axios.create({
    baseURL: BASE_URL,
  });

  api.interceptors.request.use(async (config) => {
    const token = await getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  return api;
};
