import { useQuery } from "@tanstack/react-query";
import { useUser } from "@clerk/clerk-react";
import { useApi } from "./useApi";
import { UserData, UserDataResponse } from "../types/user";

export function useUserData() {
  const { user } = useUser();
  const api = useApi();

  const fetchUserData = async (): Promise<UserData> => {
    const { data } = await api.get<UserDataResponse>(`/v2/users/me`);
    const { first_name, last_name, avatar_url, email } = data.metadata;
    const name = `${first_name} ${last_name || ""}`.trim();

    return {
      uid: data.uid,
      name,
      avatar_url,
      email,
      slack_user: data.slack_user,
    };
  };

  return useQuery({
    queryKey: ["userData", user?.id],
    queryFn: fetchUserData,
    enabled: !!user,
  });
}
