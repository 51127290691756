import React from "react";
import { useUserProfile } from "../hooks/useUserProfile";
import Spinner from "../components/spinner";
import Card from "../components/card";
import playedTeammatesIcon from "../assets/icons/playedTeammatesIcon.png";
import playedGamesIcon from "../assets/icons/playedGamesIcon.png";
import { UserData, GameType, SlackUser } from "../types/user";

import { generateSlackDeepLink } from "../lib/utils";

interface AvailableGamesProps {
  games: GameType[];
  slackUser?: SlackUser;
}

const AvailableGames: React.FC<AvailableGamesProps> = ({
  games,
  slackUser,
}) => (
  <div className="mt-8">
    <div>
      {games.map((game) => (
        <GameCard key={game.id} game={game} slackUser={slackUser} />
      ))}
    </div>
  </div>
);

interface GameCardProps {
  game: GameType;
  slackUser?: SlackUser;
}

const GameCard: React.FC<GameCardProps> = ({ game, slackUser }) => (
  <Card>
    <div className="flex flex-col">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          <img
            src={game.mini_image}
            alt={game.title}
            className="w-8 h-8 mr-2"
          />
          <h3 className="text-lg font-semibold">{game.title}</h3>
        </div>
        <div className="flex items-center">
          <span className="text-xs text-gray-500 mr-2">
            5 teammates have played
          </span>
          <img src={playedTeammatesIcon} alt="Teammates" className="w-5 h-5" />
        </div>
      </div>
      <div className="flex bg-brand/10 p-4 rounded mt-4">
        <p className="flex-grow text-sm text-brand whitespace-pre-line mr-4">
          {game.description}
        </p>
        <div className="flex items-start">
          <a
            href={generateSlackDeepLink(slackUser)}
            className="bg-brand text-white text-md px-4 py-2 rounded-[8px] hover:bg-brand/50 transition-colors whitespace-nowrap shadow-sm shadow-accent/60"
          >
            Play Now
          </a>
        </div>
      </div>
    </div>
  </Card>
);

function Profile() {
  const { userData, gamesPlayed, latestGameIcons, isLoading, isError, error } =
    useUserProfile();

  if (isLoading) {
    return (
      <div className="h-full flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="font-semibold text-red-600">
          Error: {error instanceof Error ? error.message : "An error occurred"}
        </div>
      </div>
    );
  }

  if (!userData) {
    return <div className="text-center mb-4">No user data available.</div>;
  }

  const availableGames =
    userData.slack_user?.subscription.available_games || [];

  return (
    <div className="h-full">
      <Card>
        <div className="flex items-center justify-between">
          <UserInfo userData={userData} />
          <UserStats
            gamesPlayed={gamesPlayed.length}
            latestGameIcons={latestGameIcons}
          />
        </div>
      </Card>
      <AvailableGames games={availableGames} slackUser={userData.slack_user} />
    </div>
  );
}

interface UserInfoProps {
  userData: UserData;
}

const UserInfo: React.FC<UserInfoProps> = ({ userData }) => (
  <div className="flex items-center">
    <img
      className="h-16 w-16 rounded-full mr-4"
      src={userData.avatar_url}
      alt={userData.name || "User avatar"}
    />
    <div>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {userData.name}
      </h3>
      <div className="text-[12px] text-gray-500">{userData.email}</div>
    </div>
  </div>
);

interface UserStatsProps {
  gamesPlayed: number;
  latestGameIcons: string[];
}

const UserStats: React.FC<UserStatsProps> = ({
  gamesPlayed,
  latestGameIcons,
}) => (
  <div className="flex items-center space-x-6">
    <StatItem
      icons={latestGameIcons}
      label="games played"
      value={gamesPlayed}
      defaultIcon={playedGamesIcon}
    />
    <StatItem
      defaultIcon={playedTeammatesIcon}
      label="teammates"
      value={0} // This should be calculated or fetched
    />
  </div>
);

interface StatItemProps {
  defaultIcon: string;
  icons?: string[];
  label: string;
  value: number;
}

const StatItem: React.FC<StatItemProps> = ({
  defaultIcon,
  icons,
  label,
  value,
}) => (
  <div className="flex flex-col items-center">
    <div className="flex -space-x-2 overflow-hidden">
      {icons && icons.length > 0 ? (
        icons.map((iconSrc, index) => (
          <div
            key={index}
            className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center border-2 border-white"
          >
            <img
              src={iconSrc}
              alt={`${label} ${index + 1}`}
              className="w-6 h-6"
            />
          </div>
        ))
      ) : (
        <img src={defaultIcon} alt={label} className="w-8 h-8" />
      )}
    </div>
    <p className="text-sm font-semibold text-gray-900 mt-2">{value}</p>
    <p className="text-xs text-gray-500">{label}</p>
  </div>
);

export default Profile;
