interface PlanSelectorProps {
  onSelect: (plan: "monthly" | "annual") => void;
}

function PlanSelector({ onSelect }: PlanSelectorProps) {
  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-4">Select a plan</h2>
      <div className="space-y-4">
        <button
          onClick={() => onSelect("monthly")}
          className="w-full bg-white text-brand border-2 border-gray-200 px-4 py-2 rounded-[8px] hover:bg-gray-100 transition-colors shadow-sm"
        >
          Monthly Plan • $35/month
        </button>
        <button
          onClick={() => onSelect("annual")}
          className="w-full bg-purple-800 text-white px-4 py-2 rounded-[8px] hover:bg-purple-600 transition-colors shadow-sm"
        >
          Annual Plan • $300/year
        </button>
      </div>
    </div>
  );
}

export default PlanSelector;
