import { useUserData } from "./useUserData";
import {
  GamesPlayedStats,
  Subscription,
  SubscriptionTier,
} from "../types/user";

export function useUserProfile() {
  const {
    data: userData,
    isLoading,
    isError,
    error,
    refetch: refetchUserData,
  } = useUserData();

  const gamesPlayed: GamesPlayedStats[] =
    userData?.slack_user?.games_played || [];

  const subscription: Subscription | undefined =
    userData?.slack_user?.subscription;

  const subscriptionTier: SubscriptionTier | undefined = subscription?.tier;

  const latestGameIcons: string[] = gamesPlayed
    .slice(0, 3)
    .map((game) => game.image);

  return {
    isLoading,
    isError,
    error,
    userData,
    gamesPlayed,
    subscription,
    subscriptionTier,
    latestGameIcons,
    refetchUserData,
  };
}
