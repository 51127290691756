import { useState } from "react";

import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faTrash,
  faDice,
  faUsers,
  faTrophy,
  faCog,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";

import Card from "../card";
import Modal from "../modal";
import PlanSelector from "./planSelector";

import { useApi } from "../../hooks/useApi";
import { useUserProfile } from "../../hooks/useUserProfile";

import * as Sentry from "@sentry/react";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

interface CheckoutFormProps {
  onCancel: () => void;
  plan: "monthly" | "annual";
}

function CheckoutForm({ plan, onCancel }: CheckoutFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setProcessing(true);

    try {
      const result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/settings`,
        },
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      Sentry.captureException(error);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setProcessing(false);
    }
  };
  return (
    <form onSubmit={handleSubmit} className="p-4">
      <h2 className="text-xl font-semibold mb-4">Upgrade to Premium</h2>
      <p className="mb-4 font-bold">
        Selected Plan:{" "}
        {plan === "monthly" ? "Monthly ($35/mo)" : "Annual ($300/year)"}
      </p>
      <PaymentElement />
      {error && <div className="text-red-500 mt-2">{error}</div>}
      <div className="mt-4 space-x-2">
        <button
          type="submit"
          disabled={!stripe || processing}
          className="bg-btn-gradient text-white px-4 py-2 rounded-[8px] transition-colors shadow-md shadow-accent/40 disabled:opacity-50"
        >
          {processing ? "Processing..." : "Subscribe now"}
        </button>
        <button
          type="button"
          onClick={onCancel}
          className="bg-gray-300 text-gray-700 px-4 py-2 rounded-[8px] hover:bg-gray-400 transition-colors"
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

interface FeatureItemProps {
  basic?: boolean;
  icon: IconProp;
  text: string;
}

function FeatureItem({ basic, icon, text }: FeatureItemProps) {
  const bgColor = basic ? "bg-white border-2 border-gray-100" : "bg-brand/10";
  const iconColor = basic ? "text-gray-500" : "text-brand";

  return (
    <div
      className={`${bgColor} flex flex-col items-center py-5 rounded-[8px] flex-grow`}
    >
      <FontAwesomeIcon icon={icon} className={`${iconColor} mb-2 text-lg`} />
      <span className="text-xs text-center">{text}</span>
    </div>
  );
}

function SubscriptionSection() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<"monthly" | "annual" | null>(
    null,
  );
  const api = useApi();
  const {
    subscription,
    subscriptionTier,
    isLoading,
    isError,
    refetchUserData,
  } = useUserProfile();

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setClientSecret(null);
    setSelectedPlan(null);
  };

  const handlePlanSelect = async (plan: "monthly" | "annual") => {
    setSelectedPlan(plan);
    try {
      const response = await api.post("/v2/users/setup-subscription", {
        plan,
      });
      setClientSecret(response.data.client_secret);
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error creating subscription:", error);
    }
  };

  const handleCancel = async () => {
    try {
      await api.post("/v2/users/cancel-subscription", {
        subscription_id: subscription?.stripe_subscription_id,
      });
      await refetchUserData();
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error cancelling subscription:", error);
    }
  };

  const handleReactivate = async () => {
    try {
      await api.post("/v2/users/reactivate-subscription", {
        subscription_id: subscription?.stripe_subscription_id,
      });
      await refetchUserData();
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error reactivating subscription:", error);
    }
  };

  if (isLoading) {
    return <Card>Loading subscription information...</Card>;
  }

  if (isError) {
    return (
      <Card>
        Error loading subscription information. Please try again later.
      </Card>
    );
  }

  const freeFeatures = [
    { icon: faDice, text: "A library of 6 basic games" },
    { icon: faUsers, text: "Max 10 teammates" },
    { icon: faTrophy, text: "Basic insights and leaderboards" },
  ];

  const premiumFeatures = [
    { icon: faDice, text: "A library of 12 games" },
    { icon: faUsers, text: "Unlimited team members" },
    { icon: faTrophy, text: "Individual and team stats" },
    { icon: faCog, text: "Advanced game settings" },
    { icon: faCalendar, text: "Schedule recurring events" },
  ];

  const isActivePremium =
    (subscription?.status === "active" ||
      subscription?.status === "canceling") &&
    subscriptionTier?.tier_type === "Premium";

  const isCancelling = subscription?.status === "canceling";

  return (
    <Card>
      <h2 className="text-xl font-semibold mb-4">Subscription</h2>

      {!isActivePremium && (
        <div className="mb-8">
          <div className="text-lg mb-4 flex items-center justify-between">
            <h3 className="font-bold">Free</h3>
            <span className="text-sm text-gray-500 justify-self-end">
              Current Plan
            </span>
          </div>

          <div className="flex gap-2">
            {freeFeatures.map((feature, index) => (
              <FeatureItem
                key={index}
                icon={feature.icon}
                text={feature.text}
                basic
              />
            ))}
          </div>
        </div>
      )}

      <div>
        <div className="text-lg mb-4 flex items-center justify-between">
          <h3 className="font-bold">Premium (Beta)</h3>
          {isActivePremium ? (
            <span className="text-sm text-gray-500 justify-self-end">
              Current Plan • {subscription?.billing_period || "--"}
              {isCancelling && " (cancels at period end)"}
            </span>
          ) : (
            <span className="text-sm  justify-self-end text-brand">
              $35/month or $300/year
            </span>
          )}
        </div>

        <div className="flex gap-2">
          {premiumFeatures.map((feature, index) => (
            <FeatureItem key={index} icon={feature.icon} text={feature.text} />
          ))}
        </div>

        {isActivePremium && (
          <div className="mt-4">
            {isCancelling ? (
              <div>
                <p className="text-sm text-red-700">
                  Your subscription will be cancelled at the end of the current
                  billing period.
                </p>

                <a
                  onClick={handleReactivate}
                  className="text-brand text-sm mt-2 mt-4 inline-block font-bold cursor-pointer"
                >
                  Reactivate Subscription
                </a>
              </div>
            ) : (
              <a
                onClick={handleCancel}
                className="text-red-700 text-sm inline-block font-bold cursor-pointer"
              >
                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                Cancel Subscription
              </a>
            )}
          </div>
        )}

        {!isActivePremium && (
          <div className="mt-4">
            <button
              onClick={handleOpenModal}
              className="w-full bg-btn-gradient text-white px-4 py-2 rounded-[8px] transition-colors shadow-md shadow-accent/40"
            >
              Upgrade to Premium
            </button>
          </div>
        )}
      </div>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        {!clientSecret ? (
          <PlanSelector onSelect={handlePlanSelect} />
        ) : (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm onCancel={handleCloseModal} plan={selectedPlan!} />
          </Elements>
        )}
      </Modal>
    </Card>
  );
}

export default SubscriptionSection;
